/*
*/
import React, { useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import ComplementarDadosForm from './complementarDadosForm';
import Info from '../../components/modal/Info';
import {
  Row,
  Col,
  Container
} from 'reactstrap';

import { gravarDadosMotoristaAction } from '../../actions/primeiroAcessoActions';
import { obterDados, gravarDados } from '../../utils/Session';
import Dialog from '../../components/modal/Dialog';

let dialog = React.createRef();
let info = React.createRef();

const problemasComSeusDados = () => (
  <div>
    <div>* Revise seus dados e tente novamente</div>
    <div>* Se o problema persistir entre em contato com o <a href="mailto:taxirio.cidades@rio.rj.gov.br?subject=Problemas%20com%20os%20dados">TAXI.RIO Cidades</a></div>
  </div>
);

const ComplementarDados = () => {

  const [mensagem, setMensagem] = useState({ telefone: '', email: '' });
  const [mensagemInfo, setMensagemInfo] = useState({ titulo: '', opcao: 0 });
  let history = useHistory();
  let session = obterDados();
  console.log(session);
  if (!session) {
    history.push("/");
  }

  function handleSubmit(values) {

    dialog.current.toggle();

    let driver = obterDados();
    driver.telefone = values.telefone;
    driver.email = values.email;
    driver.cityId = driver.cidade.cityId;
    driver.senha = values.senha;

    driver.bankAccount = {
      "TipoConta": values.TipoConta,
      "NumBanco": values.NumBanco,
      "Agencia": values.Agencia,
      "Conta": values.Conta,
      "DigConta": values.DigConta,
      "NomeComplTitularConta": values.NomeComplTitularConta,
      "CPFTitular": values.CPFTitular,
    }
    gravarDados(driver);

    const mensagemDlg = { telefone: values.telefone, email: values.email };
    setMensagem(mensagemDlg);
  }

  const cpfFormat = (value) => `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
  const telFormat = (value) => `(${value.substring(0, 2)})${value.substring(2, 7)}-${value.substring(7, 11)}`;

  const handleSuccess = () => {

    gravarDadosMotoristaAction(obterDados()).then((response) => {
      const { status } = response;
      if (status === 200) {
        history.push("/sucesso");
      } else {
        setMensagemInfo({ titulo: "Houve problema com seus dados" });
        info.current.toggle();
      }
    });
  }

  return !session ? <></> : (
    <>
      <Info ref={info} titulo={mensagemInfo.titulo} >
        {problemasComSeusDados()}
      </Info>
      <Dialog ref={dialog} titulo="Confirme seus dados" mensagem="" handleSuccess={() => handleSuccess()}>
        <div key="msgTelefone">{telFormat(mensagem.telefone)}</div>
        <div key="msgEmail">{mensagem.email}</div>
      </Dialog>
      <Container className="home-container">
        <Row className="complementar-dados-itens">
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <div className="caixa-informacoes-taxista">
              <div>Registro Municipal: {session.code}</div>
              <div>{cpfFormat(session.cpf||"")}</div>
              <div>{session.name}</div>
              <div id="corrigir"><Link to="/primeiro-acesso">Corrigir</Link></div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>

          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <ComplementarDadosForm onSubmit={(values, dispatch) => handleSubmit(values, dispatch, history)} />
          </Col>
        </Row>
      </Container>

    </>
  )
};

export default ComplementarDados;
