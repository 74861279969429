/*
*/
import React from "react";
import { useHistory } from 'react-router-dom';
import NavbarHome from '../../components/navbar/NavbarHome';
import Rodape from '../../components/rodape/Rodape';
import LoginForm from './loginForm';
import { loginAction } from '../../actions/loginActions';
import {
  Row,
  Col,
  Container
} from 'reactstrap';

// import { selecionarCidadeAction } from '../../actions/primeiroAcessoActions';
import { gravarDados, obterDados } from '../../utils/Session';
import Info from "../../components/modal/Info";

const Login = () => {
  let info = React.createRef();
  let history = useHistory();

  const handleSubmit = (values, dispatch, history) => {
    
    loginAction({...values, cityId: obterDados().cidade.cityId} ).then((response) => {
      const { status, data } = response.payload;
      if (status === 200) {
        const {token, displayName } = data;
        gravarDados({...obterDados(), token, displayName});
        history.push("/home");
      } else {
        // setMensagemInfo({ titulo: "Houve problema com seus dados" });
        info.current.setMensagem("Login inválido, revise os seus dados e tente novamente.")
        info.current.toggle();
      }
    });
  }

  return (
    <>
      <Info ref={info} titulo="Houve problema com seus dados"/>
      <NavbarHome />
      <Container className="home-container">
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <LoginForm onSubmit={(values, dispatch) => handleSubmit(values, dispatch, history)}/>
          </Col>
        </Row>
      </Container>

      <Rodape />
    </>
  )};



export default Login;
