/*
*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup,
  Button,
} from 'reactstrap';

import { reduxForm, Field, formValueSelector } from 'redux-form';

import renderField from '../../components/inputs/renderField';
import { required } from '../../components/validadores';


let LoginForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={(handleSubmit)}>
      <FormGroup>
        <Field validate={required} label="Registro Municipal" component={renderField} name="login" id="login" placeholder="Registro municipal" className="form-control" />
      </FormGroup>
      <FormGroup>
        <Field type="password" validate={required} label="Senha" component={renderField} name="password" id="password" placeholder="senha" className="form-control" />
      </FormGroup>
      <Button color="success">Entrar</Button>

    </form>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

LoginForm = reduxForm({
  // a unique name for the form
  form: 'loginForm'
})(LoginForm)

// Decorate with connect to read form values
const selector = formValueSelector('homeForm') // <-- same as form name
LoginForm = connect(
  state => {
    // can select values individually
    const cidade = selector(state, 'cidade')
    return {
      cidade
    }
  }
)(LoginForm)

export default LoginForm;
