import { SELECIONAR_CIDADE, VERIFICAR_DADOS_MOTORISTA } from '../actions/actionConstants';

const initialState = {
  cidade: {codigo: ''}
};
  
export const PrimeiroAcessoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECIONAR_CIDADE: {
      return {...state, cidade: action.payload};
    }
    case VERIFICAR_DADOS_MOTORISTA:
      return state;
    default:
      return state;
  }
};