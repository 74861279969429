/*!
*/
import React from "react";
// import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  Container,
} from "reactstrap";

class NavbarHome extends React.Component {
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark navbar-static-top navbar-taxirio" expand="md" id="navbar-main">
          <Container fluid={true} className="container-fluid-taxirio cabecalho">
            <div className="home-cabecalho-imagem">
              <img className="logo-cidades-home" src={require('../../assets/img/taxicidade.png')} alt="logo Taxi.rio cidades" />
            </div>
            <div className="titulo">
              <div>Área do Taxista</div>
            </div>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default NavbarHome;
