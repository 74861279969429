/*!
*/
import React from "react";
import {
  Navbar,
  Container,
} from "reactstrap";
import { useHistory, Redirect } from 'react-router-dom';
import PropTypes from "prop-types";

import {obterDados} from '../../utils/Session'

const NavbarDefault = (props) => {
  const history = useHistory();
  if(obterDados() === null || !obterDados().cidade){
    history.push("/");
    return (<Redirect to="/" />)
    ;
  }
  return (
    <>
      <Navbar className="navbar-top navbar-dark navbar-static-top navbar-taxirio navbar-taxirio-default" id="navbar-main">
        <Container fluid={true} className="container-fluid-taxirio cabecalho-default">
          <div className="default-cabecalho-imagem">
            <img className="logo-cidades-home" src={require('../../assets/img/taxicidade.png')} alt="Logo Taxi.rio cidades" />
          </div>
          <div className="titulo-default">
            <div>{props.titulo}</div>
          </div>

        </Container>
        
        <Container fluid={true} className="base-cabecalho">
          <div className="default-cabecalho-nome-cidade">{obterDados().cidade.name}</div>
          <div className="sair" onClick={()=>history.push("/")}>Sair</div>
        </Container>
      </Navbar>
      
    </>
  )
};

NavbarDefault.propTypes = {
  titulo: PropTypes.string.isRequired,
}

export default NavbarDefault;
