/*
*/
import React from "react";
import Rodape from '../../components/rodape/Rodape';

import {
  Row,
  Col,
  Container
} from 'reactstrap';

const Home = () => {

  return (
    <>
      <Container className="home-container">
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            
          </Col>
        </Row>
      </Container>

      <Rodape />
    </>
  )};



export default Home;
