/*
*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup,
  Button,
} from 'reactstrap';
import { createTextMask } from 'redux-form-input-masks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import { reduxForm, Field } from 'redux-form';
import renderField from '../../components/inputs/renderField';
import renderCheckbox from '../../components/inputs/renderCheckbox';
import { required, email, checkIsTrue, minLength8 } from '../../components/validadores';
import renderSelect from "../../components/inputs/renderSelect";

let ComplementarDadosForm = props => {
  const { handleSubmit, isUpdate = false } = props;
  const [showPassword, setShowPassword] = useState(false);

  const cpfMask = createTextMask({
    pattern: '999.999.999-99',
  });

  const telefoneMask = createTextMask({
    pattern: '(99)9-9999-9999',
  });

  return (
    <form onSubmit={(handleSubmit)}>
      <div className="legenda">Taxista, complemente seus dados</div>

      <FormGroup>
        <Field label="e-mail" validate={[required, email]} type="email" required component={renderField} name="email" id="email" placeholder="e-mail" />
      </FormGroup>
      <FormGroup>
        <Field {...telefoneMask} type="tel" component={renderField} label="Telefone com código de área" errorLabel="Telefone" name="telefone" id="telefone" placeholder="(21)98765-4321" />
      </FormGroup>
      {!isUpdate &&
        <FormGroup>
          <div onClick={() => setShowPassword(!showPassword)} className="show-password"><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></div>
          <Field validate={minLength8} label="Senha" required component={renderField} type={showPassword ? 'text' : 'password'} name="senha" id="senha" placeholder="Mínimo de 8 caracteres" />
        </FormGroup>
      }
      <FormGroup>
        <Field label="Tipo de conta" name="TipoConta" component={renderSelect} className="form-control" validate={required}>
          <option value={undefined}></option>
          <option value="C">Conta Corrente</option>
          <option value="P">Conta Poupança</option>
        </Field>
      </FormGroup>

      <FormGroup>
        <Field label="Banco" validate={required} type="number" required component={renderField} name="NumBanco" id="NumBanco" placeholder="Código do banco" />
      </FormGroup>

      <FormGroup>
        <Field label="Agência (sem o DV)" validate={required} type="number" required component={renderField} name="Agencia" id="Agencia" placeholder="Agência" />
      </FormGroup>

      <FormGroup>
        <Field label="Nº da Conta" validate={required} type="number" required component={renderField} name="Conta" id="Conta" placeholder="" />
      </FormGroup>

      <FormGroup>
        <Field label="DV Conta" validate={required} type="number" required component={renderField} name="DigConta" id="DigConta" placeholder="" />
      </FormGroup>

      <FormGroup>
        <Field label="Nome completo do titular da conta" validate={required} type="Text" required component={renderField} name="NomeComplTitularConta" id="NomeComplTitularConta" placeholder="" />
      </FormGroup>

      <FormGroup>
        <Field validate={required} {...cpfMask} label="CPF do titular da conta" component={renderField} name="CPFTitular" id="CPFTitular" placeholder="Digite o CPF" className="form-control" />
      </FormGroup>

      {
        !isUpdate && (
          <FormGroup>
            <div className="terms">
              <Field
                name="terms"
                errorLabel="Você precisa concordar para poder continuar"
                id="terms"
                component={renderCheckbox} type="checkbox"
                validate={checkIsTrue}
              >
                Declaro que li e concordo com os <span className="terms-link">Termos de Uso e Políticas de Privacidade  <FontAwesomeIcon icon={faExternalLinkAlt} /></span> do TAXI.RIO Cidades
              </Field>
            </div>
          </FormGroup>
        )
      }
      <Button size='md' type="submit">Continuar</Button>
    </form >
  );
}

ComplementarDadosForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  obterDadosMotorista: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
};

ComplementarDadosForm = reduxForm({
  // a unique name for the form
  form: 'complementarDados',
  enableReinitialize: true
})(ComplementarDadosForm)

// Decorate with connect to read form values
// const selector = formValueSelector('complementarDadosForm') // <-- same as form name
const mapStateToProps = (state) => {


  return { initialValues: state.complementarDados } // pull initial values from account reducer
  /*state => {
    return ({
      initialValues: state.ComplementarDados // pull initial values from account reducer
    })}*/
}

ComplementarDadosForm = connect(
  /*state => {
    // can select values individually
    const email = selector(state, 'email');
    const telefone = selector(state, 'telefone');
    const senha = selector(state, 'senha');
    const { complementarDados } = state;
    console.log("STATE",state);
    return {
      telefone,
      email,
      senha,
      complementarDados
    }
  }*/
  mapStateToProps
)(ComplementarDadosForm)


export default ComplementarDadosForm;
