/*
*/
import React,{useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import NavbarHome from '../../components/navbar/NavbarHome';
import Rodape from '../../components/rodape/Rodape';
import WelcomeForm from './welcomeForm';
import {obterCidadesAction} from '../../actions/primeiroAcessoActions';
import {
  Row,
  Col,
  Container
} from 'reactstrap';

// import { selecionarCidadeAction } from '../../actions/primeiroAcessoActions';
import { gravarDados } from '../../utils/Session';

const Welcome = () => {
  let history = useHistory();
  sessionStorage.clear();

  const [cidades, setCidades] = useState([]);
  
  const handleSubmit = (values, dispatch, history) => {
    // e.preventDefault();
    if(!values || !values.cidade) {
      alert(`Selecione uma cidade`);
      return;
    }
    // dispatch(selecionarCidadeAction(values));
    let dadosPassageiro = {cidade:cidades[values.cidade]};
    gravarDados(dadosPassageiro);
    history.push(values.irPara);
  }

  useEffect( () => {

    async function fetchData() {
      const cities = await obterCidadesAction();
      setCidades(cities.payload.data);
    }
    fetchData();
  },[]);
  
  return (
    <>
      <NavbarHome />
      <Container className="home-container">
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <WelcomeForm cidades={cidades} onSubmit={(values, dispatch) => handleSubmit(values, dispatch, history)}/>
          </Col>
        </Row>
      </Container>

      <Rodape />
    </>
  )};



export default Welcome;
