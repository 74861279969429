/*
*/
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import TrocarSenhaForm from './trocarSenhaForm';
import Info from '../../components/modal/Info';
import {
  Row,
  Col,
  Container
} from 'reactstrap';

import { trocarSenhaMotoristaAction } from "../../actions/trocarSenhaActions";

import { obterDados } from '../../utils/Session';

let info = React.createRef();

const TrocarSenha = () => {

  const [mensagemInfo, setMensagemInfo] = useState({ titulo: '', opcao: 0 });

  let history = useHistory();
  let session = obterDados();

  if (!session) {
    history.push("/");
  }
  
  function handleSubmit(values) {

    trocarSenhaMotoristaAction(values).then((response) => {
      const { status } = response;
      if (status === 200) {
        info.current.setTitulo("Dados alterados com sucesso");
        info.current.listenerClose( () => history.push("/home") );
        info.current.toggle();
      } else {
        setMensagemInfo({ titulo: "Houve problema com seus dados" });
        info.current.listenerClose( undefined );
        info.current.toggle();
      }
    });
  }

  // const cpfFormat = (value) => `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
  
  return !session ? <></> : (
    <>
      <Info ref={info} titulo={mensagemInfo.titulo} >
        
      </Info>
      
      <Container className="home-container">
        <br />
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <TrocarSenhaForm onSubmit={(values, dispatch) => handleSubmit(values, dispatch, history)} isUpdate />
          </Col>
        </Row>
      </Container>

    </>
  )
};

export default TrocarSenha;
