import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup,
  Button,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

import { reduxForm, Field } from 'redux-form';
import renderField from '../../components/inputs/renderField';

import { minLength8 } from '../../components/validadores';

let TrocarSenhaForm = props => {
  const { handleSubmit } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <form onSubmit={(handleSubmit)}>
      <div className="legenda">Taxista, informe uma nova senha</div>

      <FormGroup>
        <div onClick={() => setShowPassword(!showPassword)} className="show-password"><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></div>
        <Field validate={minLength8} label="Senha" required component={renderField} type={showPassword ? 'text' : 'password'} name="senha" id="senha" placeholder="Mínimo de 8 caracteres" />
      </FormGroup>

      <Button size='md' type="submit">Continuar</Button>
    </form >
  );
}

TrocarSenhaForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
};

TrocarSenhaForm = reduxForm({
  // a unique name for the form
  form: 'complementarDados',
  enableReinitialize: true
})(TrocarSenhaForm)

// Decorate with connect to read form values
const mapStateToProps = (state) => {
  return { initialValues: state.complementarDados } // pull initial values from account reducer
}

TrocarSenhaForm = connect(
  mapStateToProps
)(TrocarSenhaForm)


export default TrocarSenhaForm;
