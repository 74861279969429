/*!
*/
import React from "react";
// reactstrap components
import {
  Navbar,
  Container,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
 
class Rodape extends React.Component {
  render() {
    return (
      <>
        <Navbar className="navbar-rodape navbar-default navbar-fixed-bottom">
          <Container fluid={true}>
            <Navbar  className="navbar-left">
              <a href="http://www.rio.rj.gov.br/web/iplanrio" target="_blank" rel="noopener noreferrer"><img width="50" src={require('../../assets/img/logotipo_iplanrio.png')} alt="Logo da Iplanrio"/></a>
              <div className="copyright">
                <p className="text-left">© Desenvolvido por Iplanrio</p>
                <p className="text-left">Versão 0.0.1a</p>
              </div>
            </Navbar>
            <Navbar className="navbar-right d-none d-sm-block">
              <ul className="list-inline social-media">
                <li className="list-inline-item"><a href="http://www.rio.rj.gov.br/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faGlobeAmericas} /></a></li>
                <li className="list-inline-item"><a href="https://www.facebook.com/PrefeituradoRio" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} /></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/prefeitura_rio/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </Navbar>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default Rodape;
