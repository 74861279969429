import * as types from './actionConstants';
import apiRequest, {apiRequestBO} from '../utils/ApiRequest'
export const selecionarCidadeAction = async (value) => {
  const cidade = { codigo:value.cidade }
  return ({
    type: types.SELECIONAR_CIDADE,
    payload: cidade
  });
};

export const verificarDadosMotoristaAction = async (value) => {
  let resultado;
  await apiRequest().post('drivers/checkFirstAccess',value).then( response => {
    resultado = response;
  }).catch(error => {
    // console.error("action",error.response);
    resultado = error.response;
  });

  return resultado;
}

export const gravarDadosMotoristaAction = async (value) => {
  let resultado;
  await apiRequest().post('drivers/updateFirstAccess',value).then(response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  })
  return resultado;
};

export const obterCidadesAction = async () => {
  let resultado;
  
  await apiRequestBO().post('/cities/getall').then( response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  });

  return ({
    type: types.OBTER_CIDADES,
    payload: resultado
  });
}