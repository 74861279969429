/*
*/
import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import Info from '../../components/modal/Info';
import PrimeiroAcessoForm from './primeiroAcessoForm';

import {
  Row,
  Col,
  Container
} from 'reactstrap';

import { verificarDadosMotoristaAction } from '../../actions/primeiroAcessoActions';
import {obterDados, gravarDados} from '../../utils/Session';

let info = React.createRef();

const problemasComSeusDados = () => (
  <div>
    <div>* Revise seus dados e tente novamente</div>
    <div>* Se o problema persistir entre em contato com o <a href="mailto:taxirio.cidades@rio.rj.gov.br?subject=Problemas%20com%20os%20dados">TAXI.RIO Cidades</a></div>
  </div>
);

const naoHabilitado = () => (
  <div>
    <div>* Entre em contato com sua Prefeitura e solicite a sua inclusão no serviço.</div>
    <div>* Dúvidas? Entre em contato com o <a href="mailto:taxirio.cidades@rio.rj.gov.br?subject=Motorista%20não%20habilitado">TAXI.RIO Cidades</a></div>
  </div>
);

const PrimeiroAcesso = () => {

  const handleSubmit = (values, dispatch, history) => {
    const pesquisa = { 
      cityId: obterDados().cidade.cityId,
      cpf: values.cpf,
      name: values.nome,
      code: values.codigo
    };
  
    verificarDadosMotoristaAction(pesquisa).then((res) => {
      const {status, data} = res;
      
      if(status === 200) {
        let dados = obterDados();
        dados.cpf = values.cpf;
        dados.name= values.nome;
        dados.code= values.codigo;
        dados.driverId = data.driverId;
  
        gravarDados(dados);
        history.push("/complementar-dados");
      } else {
        // TODO mensagem de erro 
        if(status === 434){
          setMensagem({titulo:"Não habilitado pela sua prefeitura", opcao:2} );
        } else {
          setMensagem({titulo:"Houve problema com seus dados", opcao:2} );
        }
        
        info.current.toggle();
      }
    }).catch((e)=>{
      console.log(e);  
    });
    
    
    history.push("/primeiro-acesso");
  }

  const [mensagem, setMensagem] = useState({titulo:'', opcao:0});
  let history = useHistory();
  let session = obterDados();
  if(!session) {
    history.push("/");
  }
  return (
    <>
      <Info ref={info} titulo={mensagem.titulo} >
        {
          (mensagem.opcao===1 && problemasComSeusDados()) ||
          (mensagem.opcao===2 && naoHabilitado())
        }
      </Info>

      <Container className="home-container">
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <PrimeiroAcessoForm onSubmit={(values, dispatch) => handleSubmit(values, dispatch, history)}/>
          </Col>
        </Row>
      </Container>

    </>
  )};

export default PrimeiroAcesso;
