import React from 'react';
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class Dialog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {modal:false};
  }
  
  toggle () {
    const {modal} = this.state;
    this.setState({modal:!modal});
  }

  render() {
    const {
      className,
      titulo,
      mensagem,
      handleSuccess
    } = this.props;
    const { modal } = this.state;
    return (
      <div>
        <Modal isOpen={modal} toggle={()=>this.toggle()} className={className}>
          <ModalHeader toggle={()=>this.toggle()}>{titulo}</ModalHeader>
          <ModalBody>
            {mensagem}
            {this.props.children}
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={()=>{ handleSuccess();this.toggle()}}>Confirmar</Button>{' '}
            <Button color="danger" onClick={()=>this.toggle()}>Corrigir</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

Dialog.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  titulo: PropTypes.string.isRequired,
  mensagem: PropTypes.string
};

export default Dialog;