/*
*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup, 
  Button,
} from 'reactstrap';

import { reduxForm, Field, formValueSelector } from 'redux-form';
import { createTextMask } from 'redux-form-input-masks';

import renderField from '../../components/inputs/renderField';
import { required } from '../../components/validadores';

let PrimeiroAcessoForm = props => {
  const { handleSubmit } = props;

  const cpfMask = createTextMask({
    pattern: '999.999.999-99',
  });
  
  return (
    <form onSubmit={(handleSubmit)}>
      <div className="legenda">Taxista, informe alguns dados pessoais para confirmar sua identidade</div>
      <br/>
      <FormGroup>
        <Field validate={required} label="Registro Municipal" component={renderField} name="codigo" id="codigo" placeholder="Registro municipal" className="form-control"/>
      </FormGroup>
      <FormGroup>
        <Field validate={required} {...cpfMask} label="CPF" component={renderField} name="cpf" id="cpf" placeholder="Digite o CPF" className="form-control"/>
      </FormGroup>
      <FormGroup>
        <Field label="Nome completo" component={renderField} name="nome" id="nome" placeholder="Nome conforme consta no seu cadastro" className="form-control"/>
      </FormGroup>
      <Button size='md' type="submit">Continuar</Button>
    </form>
  );
}

PrimeiroAcessoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

PrimeiroAcessoForm = reduxForm({
  // a unique name for the form
  form: 'driver'
})(PrimeiroAcessoForm)

// Decorate with connect to read form values
const selector = formValueSelector('prmeiroAcessoForm') // <-- same as form name
PrimeiroAcessoForm = connect(
  state => {
    // can select values individually
    const codigo = selector(state, 'codigo');
    const cpf = selector(state, 'cpf');
    const nome = selector(state, 'nome');
    const {primeiroAcesso} = state;
    return {
      codigo,
      cpf,
      nome,
      primeiroAcesso
    }
  }
)(PrimeiroAcessoForm)

export default PrimeiroAcessoForm;
