import React from 'react';
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class Info extends React.Component {
  
  constructor(props) {
    super(props);
    const {titulo="", mensagem=""} = this.props;
    this.state = {modal:false, titulo:titulo, mensagem:mensagem};
    this.onClose = props.onClose;
  }
  
  toggle () {

    const {modal} = this.state;
    if(modal && this.onClose) {
      // onClose
      
      this.onClose();
    }

    this.setState({modal:!modal});
  }

  listenerClose (e) {
    this.onClose = e;
  }

  setTitulo (titulo) {
    this.setState({titulo:titulo});
  }

  setMensagem (msg) {
    this.setState({mensagem:msg});
  }

  render() {
    const {
      className
    } = this.props;
    const { modal, titulo, mensagem } = this.state;
    return (
      <div>
        <Modal isOpen={modal} toggle={()=>this.toggle()} className={className} >
          <ModalHeader toggle={()=>this.toggle()}>{titulo}</ModalHeader>
          <ModalBody>
            {mensagem}
            {this.props.children}
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={()=>this.toggle()}>Ok</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

Info.propTypes = {
  titulo: PropTypes.string.isRequired,
  mensagem: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  onClose: PropTypes.func

};

export default Info;