/*
*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup, 
  Label, 
  Button,
} from 'reactstrap';

import { reduxForm, Field, formValueSelector } from 'redux-form';
import renderField from "../../components/inputs/renderField";

let WelcomeForm = props => {
  // eslint-disable-next-line react/prop-types
  const { handleSubmit, cidades, change } = props;

  const setPage = (destino) => {
    change('irPara', destino)
  }
  return (
    <form onSubmit={(handleSubmit)}>
      <Field type="hidden" name="irPara" component={renderField} value=""/>
      <FormGroup>
        <Label for="cidade">Escolha sua cidade</Label>

        <Field name="cidade" component="select" className="form-control">
          <option value=""></option>
          {cidades.map((c,idx) => (<option key={c.cityId} value={idx}> {c.name} </option>))}
          
        </Field>
      </FormGroup>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <Button onClick={() => setPage('primeiro-acesso')} type="submit">Primeiro acesso</Button>
        <Button onClick={() => setPage('login')} type="submit">Já tenho senha</Button>
      </div>
    </form>
  );
}

WelcomeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cidades: PropTypes.array.isRequired,
};

WelcomeForm = reduxForm({
  // a unique name for the form
  form: 'homeForm'
})(WelcomeForm)

// Decorate with connect to read form values
const selector = formValueSelector('homeForm') // <-- same as form name
WelcomeForm = connect(
  state => {
    // can select values individually
    const cidade = selector(state, 'cidade')
    return {
      cidade      
    }
  }
)(WelcomeForm)

export default WelcomeForm;
