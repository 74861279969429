import { OBTER_DADOS_MOTORISTA } from '../actions/actionConstants';

const initialState = {
  cidade: { codigo: '', },

};

export const ComplementarDadosReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case OBTER_DADOS_MOTORISTA:
      // eslint-disable-next-line no-case-declarations
      const { user, bankAccount } = action.payload.data;
      
      if( !user || !bankAccount) {
        return state;
      }
      
      return {  
        email: user.email, 
        telefone: user.phoneNumber,
        TipoConta: bankAccount.TipoConta,
        NumBanco: bankAccount.NumBanco,
        DigConta: bankAccount.DigConta,
        Conta: bankAccount.Conta,
        Agencia: bankAccount.Agencia,
        NomeComplTitularConta: bankAccount.NomeComplTitularConta,
        CPFTitular: bankAccount.CPFTitular,
      }

    default:
      return state;
  }
};