/* eslint-disable react/prop-types */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/taxirio.css';
import NavbarDefault from './components/navbar/NavbarDefault';
import NavbarDefaultAutenticado from './components/navbar/NavbarDefaultAutenticado';

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import Rodape from './components/rodape/Rodape';
import {Home, AtualizarDados, Login, PrimeiroAcesso, ComplementarDados, Sucesso, Welcome, TrocarSenha} from './paginas';
import { isAuthenticated } from './services/security.js'


function App() {
  // eslint-disable-next-line react/prop-types
  const RotaTemaPadrao = ({component: Component, titulo, ...rest }) => (
    <Route
      { ...rest }
      render={props => (
        <>
          <NavbarDefault titulo={titulo}/>
          <Component {...props} />
          <Rodape/>
        </>
      )}
    />
  );

  const RotaTemaAutenticado = ({component: Component, titulo, ...rest }) => (
    <Route
      { ...rest }
      render={props => (
        <>
          {!isAuthenticated() && <Redirect to={"/"} /> }
          <NavbarDefaultAutenticado titulo={titulo}/>
          <Component {...props} />
          <Rodape/>
        </>
      )}
    />
  );

  return (
    <>
      <Router>
        <Route exact path="/" component={Welcome} />
        <Route exact path="/login" component={Login} />
        <RotaTemaPadrao path="/primeiro-acesso" component={PrimeiroAcesso} titulo="Primeiro acesso"/>
        <RotaTemaPadrao path="/complementar-dados" component={ComplementarDados} titulo="Complementar dados"/>
        <RotaTemaPadrao path="/sucesso" component={Sucesso} titulo="Complementar dados"/>
        <RotaTemaAutenticado path="/home" component={Home} titulo="Área do taxista"/>
        <RotaTemaAutenticado path="/atualizar-dados" component={AtualizarDados} titulo="Atualizar dados"/>
        <RotaTemaAutenticado path="/trocar-senha" component={TrocarSenha} titulo="Trocar senha"/>
      </Router>
    </>
  );
}

export default App;
