/*
*/
import React from "react";
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Container
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { obterDados } from '../../utils/Session';

const Sucesso = () => {

  let history = useHistory();
  let session = obterDados();

  if (!session) {
    history.push("/");
  }
  sessionStorage.clear();
  return (
    <>
      <Container className="home-container">
        <Row className="complementar-dados-itens">
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <div className="texto-parabens"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Parabéns, só falta a sua foto para acessar o TAXI.RIO Cidades</div>
          </Col>
        </Row>
        <br />
        <Row className="complementar-dados-itens">
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <div className="sucesso-info">Dirija-se ao Posto de Atendimento do TAXI.RIO Cidades da sua Prefeitura para tirar e importar sua foto</div>
          </Col>
        </Row>
        <Row className="complementar-dados-itens">
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            {session.cidade.serviceStations.map(s => 
              <div className="sucesso-dados-prefeitura">
                {s.name}<br />
                <div style={{fontSize:"0.9em", color:'#555'}}>{s.address}</div> 
              </div>
            )}

          </Col>
        </Row>
      </Container>
    </>
  )
};

export default Sucesso;
