import React from 'react';

// eslint-disable-next-line react/prop-types
const renderCheckbox = ({ input, errorLabel, placeholder, children, type, meta: { touched, error, warning } }) => (
  <>
    <input {...input} placeholder={placeholder} type={type}/>
    {children}
    {touched && ((error && <span className="error">{`${errorLabel?errorLabel:''} ${error}`}</span>) || (warning && <span>{warning}</span>))}
  </>
)

export default renderCheckbox;