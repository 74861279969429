/*
*/
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import ComplementarDadosForm from '../complementarDados/complementarDadosForm';
import Info from '../../components/modal/Info';
import {
  Row,
  Col,
  Container
} from 'reactstrap';

import { obterDadosMotoristaAction, atualizarDadosMotoristaAction } from "../../actions/atualizarDadosActions";

import { obterDados } from '../../utils/Session';

let info = React.createRef();

const AtualizarDados = () => {

  const [mensagemInfo, setMensagemInfo] = useState({ titulo: '', opcao: 0 });

  const dispatch = useDispatch();

  let history = useHistory();
  let session = obterDados();

  if (!session) {
    history.push("/");
  }
  
  useEffect(()=> {
    dispatch(obterDadosMotoristaAction());
  },[]);
  

  function handleSubmit(values) {

    //dialog.current.toggle();

    let driver = obterDados();
    driver.telefone = values.telefone;
    driver.email = values.email;

    driver.bankAccount = {
      "TipoConta": values.TipoConta,
      "NumBanco": values.NumBanco,
      "Agencia": values.Agencia,
      "Conta": values.Conta,
      "DigConta": values.DigConta,
      "NomeComplTitularConta": values.NomeComplTitularConta,
      "CPFTitular": values.CPFTitular,
    }
    atualizarDadosMotoristaAction(driver).then((response) => {
      const { status } = response;
      if (status === 200) {
        info.current.setTitulo("Dados alterados com sucesso");
        info.current.listenerClose( () => history.push("/home") );
        info.current.toggle();
      } else {
        setMensagemInfo({ titulo: "Houve problema com seus dados" });
        info.current.listenerClose( undefined );
        info.current.toggle();
      }
    });
  }

  // const cpfFormat = (value) => `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
  
  return !session ? <></> : (
    <>
      <Info ref={info} titulo={mensagemInfo.titulo} >
        
      </Info>
      
      <Container className="home-container">
        <br />
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <ComplementarDadosForm onSubmit={(values, dispatch) => handleSubmit(values, dispatch, history)} isUpdate />
          </Col>
        </Row>
      </Container>

    </>
  )
};

export default AtualizarDados;
