import * as types from './actionConstants';
import apiRequest from '../utils/ApiRequest'
import { obterDados } from '../utils/Session';

export const atualizarDadosMotoristaAction = async (value) => {
  let resultado;
  let config = {
    headers: { "Authorization": `Bearer ${obterDados().token}` }
  }
  await apiRequest().post('drivers/updateData',value, config).then(response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  })
  return resultado;
};


export const obterDadosMotoristaAction = async () => {
  let resultado;
  
  let config = {
    headers: { "Authorization": `Bearer ${obterDados().token}` }
  }

  await apiRequest().get('drivers/getInformation', config).then(response => {
    resultado = response;
  }).catch(error => {
    console.log('error');
    resultado = error.response;
  })
  return ({
    type: types.OBTER_DADOS_MOTORISTA,
    payload: resultado
  });

};

export const trocarSenhaMotoristaAction = async (value) => {
  let resultado;
  let config = {
    headers: { "Authorization": `Bearer ${obterDados().token}` }
  }
  await apiRequest().post('drivers/updatePassword',value, config).then(response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  })
  return resultado;
};
