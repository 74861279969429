export const required = value => (value == null ? 'Inválido' : undefined);
export const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'inválido'
    : undefined
);

export const checkIsTrue = value => (!value ? 'Inválido' : undefined);
export const minLength = (value,size) => (value === undefined || value.length < size?`Mínimo de ${size} caracteres`:undefined )
export const minLength8 = (value) => (value === undefined || value.length < 8?`Mínimo de ${8} caracteres`:undefined )
